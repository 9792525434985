import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useParams,  NavLink, Link, useNavigate } from 'react-router-dom'; 
import { Modal, Input, Button, Drawer, Tooltip, Popconfirm, Space, message, Menu, Form, Dropdown, Checkbox, Select } from 'antd';
import supabase from '../supabaseClient';
import Navbar from '../pages/Navbar';
import { QuestionCircleOutlined, SettingOutlined, CopyOutlined } from '@ant-design/icons';
import { parseISO, format } from 'date-fns';
import LoadingComponent from '../components/LoadingComponent';
import { UserContext } from '../UserContext';
import { LicenseContext } from "../LicenseContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include the Quill CSS

const { Option } = Select;


function FormPayments() {
  const [form, setForm] = useState(null);
  
  const { checkFormAccess } = useContext(UserContext);
  const { formId } = useParams();
  const [isConnected, setIsConnected] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editingTitle, setEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [selectionType, setSelectionType] = useState('checkbox');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sendEmailOnSubmit, setSendEmailOnSubmit] = useState(false);
   const [emailInput, setEmailInput] = useState('');
  const [otherRecipients, setOtherRecipients] = useState([]);
  const [sendEmailToSubscriber, setSendEmailToSubscriber] = useState(false);
  const [emailTitle, setEmailTitle] = useState("");
  const [replyToAddress, setReplyToAddress] = useState("");
  const [emailContent, setEmailContent] = useState("");
  
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { licenseStatus } = useContext(LicenseContext);
 

  useEffect(() => {
    const fetchConnectionStatus = async () => {
        const { data, error } = await supabase
            .from('forms')
            .select('connected_account_id')
            .eq('form_id', formId)
            .single();

        if (error) {
            console.error('Error fetching connection status:', error);
            return;
        }

        setIsConnected(!!data?.connected_account_id);
    };

    fetchConnectionStatus();
}, [formId]);

const handleConnect = () => {
    window.location.href = `https://magicform.onrender.com/stripe/connect?form_id=${formId}`;
};

const handleDisconnect = async () => {
    try {
        const { error } = await supabase
            .from('forms')
            .update({ connected_account_id: null })
            .eq('form_id', formId);

        if (error) {
            console.error("Error disconnecting from Stripe:", error);
            alert("Failed to disconnect from Stripe.");
            return;
        }

        setIsConnected(false);
        alert("Disconnected from Stripe successfully.");
    } catch (err) {
        console.error("Unexpected error:", err);
    }
};




  useEffect(() => {
    const verifyAccess = async () => {
      const access = await checkFormAccess(formId);
      setHasAccess(access);
      if (!access) {
        // Redirect if no access
      navigate('/');
      }
    };

    verifyAccess();
  }, [formId, checkFormAccess, navigate]);





  const isActive = (path) => {
    return window.location.pathname === path;
  };


  const deleteForm = async () => {
    try {
      const { error } = await supabase.from('forms').delete().eq('form_id', formId);
      if (error) {
        throw error;
      }
      message.success('Form deleted successfully');
      window.location.href = '/'; // Redirect to '/'
    } catch (error) {
      console.error('Error deleting form:', error);
      message.error('Failed to delete form');
    } finally {
      setDeleteLoading(false);
    }
  };


 

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(formId)
      .then(() => {
        message.success('Form ID copied to clipboard');
      })
      .catch(() => {
        message.error('Failed to copy Form ID to clipboard');
      });
  }, [formId]);

  

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleTitleSave = async () => {
    try {
      await supabase.from('forms').update({ name: newTitle }).eq('form_id', formId);
      setForm((prevForm) => ({ ...prevForm, name: newTitle }));
      setEditingTitle(false);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error updating form title:', error);
      message.error('Failed to update form title');
    }
  };

  useEffect(() => {
    const fetchForm = async () => {
      const { data, error } = await supabase.from('forms').select('*').eq('form_id', formId).single();
      if (error) {
        console.error('Error fetching form:', error);
      } else {
        setForm(data);
        setOtherRecipients(data.other_recipients || []);
        setSendEmailOnSubmit(data.send_email_on_submit || false);
      }

      const { data: submissionsData, error: submissionsError } = await supabase
        .from('submissions')
        .select('*')
        .eq('form_id', formId)
        .order('created_at', { ascending: false });
      if (submissionsError) {
        console.error('Error fetching submissions:', submissionsError);
      } else {
        setSubmissions(submissionsData);
      }
    };

    fetchForm();
  }, [formId]);




  const menu = (
    <Menu>
      <Menu.Item key="edit-title"  onClick={() => {
          setNewTitle(form?.name); // Set newTitle to the current form's name
          setIsModalVisible(true); // Then, show the modal
        }}>
        Edit Form Name
      </Menu.Item>
      <Menu.Item key="delete-form">
        <Popconfirm
          placement="topLeft"
          title="Delete Form"
          description="Are you sure? After deleting the form all data will be gone forever."
          onConfirm={deleteForm}
          okText="Yes"
          cancelText="No"
        >
          Delete Form
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  if (!form) {
    return <div><LoadingComponent /></div>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
      <Navbar />
      <div style={{width: '100%', maxWidth: 1200, userSelect: 'none', paddingTop: 100, display: 'flex', flexDirection: 'row', justifyContent:'flex-start', gap: 8,  alignItems: 'center', }}>
        <p className="backToForms" onClick={() => navigate('/')}>Forms</p> <p className='sub' style={{paddingTop: 4}}> ﹥ </p> <p style={{fontWeight:'bold'}}>{form.name}</p>
      </div>
      <div style={{width: '100%', maxWidth: 1200, paddingTop: 24}}>
        

      <div style={{ marginBottom: 24, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Modal
            title="Edit Title"
            visible={isModalVisible}
            onOk={handleTitleSave} // Assuming handleTitleSave not only saves but also hides the modal
            onCancel={() => setIsModalVisible(false)}
            okText="Save"
            cancelText="Cancel"
          >
            <Input type="text" value={newTitle} onChange={handleTitleChange} />
          </Modal>

        

        
        </div>

        <div style={{borderBottom: '1px solid #2A2A2C', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: 16, paddingBottom: 8}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 16}}>
            <NavLink to={`/forms/${formId}`} className={isActive(`/forms/${formId}`) ? "navItem activeNavItem" : "navItem" }>
              <p>Submissions</p>
            </NavLink>

            <NavLink to={`/forms/${formId}/settings`} className={isActive(`/forms/${formId}/settings`) ? "navItem activeNavItem" : "navItem" }>
              <p>Notifications</p>
            </NavLink>

            <NavLink to={`/forms/${formId}/integrations`}
              className={
                isActive(`/forms/${formId}/integrations`)
                  ? "navItem activeNavItem"
                  : "navItem"
              }
            >
              <p >Integrations
              </p>
            </NavLink>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 8}}>
            <Dropdown overlay={menu}>

<Button><SettingOutlined /> Edit form</Button>
            </Dropdown>
          <Button  icon={<CopyOutlined />} onClick={copyToClipboard}>Copy Form ID</Button>
          
          </div>
        </div>




      </div>

     


      <div className="payments">
      <div className="row">
            <div>
                <p className="bold">
                    Connect with Stripe
                </p>
                {isConnected ? (
                <p className="sub">Your form is connected to Stripe.</p>
            ) : (
                <p className="sub">Connect your form to Stripe</p>
            )}
            </div>
            {isConnected ? (
                <button onClick={handleDisconnect}>Disconnect from Stripe</button>
            ) : (
                <button onClick={handleConnect}>Connect to Stripe</button>
            )}
        </div>
        
      </div>
    </div>
  );
}

export default FormPayments;
